import React from 'react';
import ThreeSteps0 from 'src/images/in-store-kyc-and-checkout/three-steps-0.png';
import ThreeSteps1 from 'src/images/in-store-kyc-and-checkout/three-steps-1.png';
import ThreeSteps2B from 'src/images/in-store-kyc-and-checkout/three-steps-2b.png';
import ThreeSteps from './ThreeSteps';

const DATA = [
  {
    image: ThreeSteps0,
    info:
      '1. ペイディアプリをダウンロードして、運転免許証またはマイナンバーカードで本人確認する。',
  },
  {
    image: ThreeSteps1,
    info: '2. ペイディアプリでご利用可能額を確認する。',
  },
  {
    image: ThreeSteps2B,
    info:
      '3. お支払い回数と金額を確認して、決済を完了。毎月27日までに、コンビニ・銀行振込・口座振替でお支払い。',
  },
];

const DATA_SP = DATA.map((item) => ({
  ...item,
  info: item.info.substring(3),
}));

export default function ThreeStepsInStoreCheckout() {
  return <ThreeSteps data={DATA} dataSP={DATA_SP} />;
}

import React from 'react';
import { Layout } from 'src/components';
import Badges from 'src/components/InStoreKYCAndCheckout/Badges';
import BadgesBottom from 'src/components/InStoreKYCAndCheckout/BadgesBottom';
import FAQ from 'src/components/InStoreKYCAndCheckout/FAQ';
import FloatingButton from 'src/components/InStoreKYCAndCheckout/FloatingButton';
import Footer from 'src/components/InStoreKYCAndCheckout/Footer';
import Payment from 'src/components/InStoreKYCAndCheckout/Payment';
import ThreeStepsInStoreCheckout from 'src/components/InStoreKYCAndCheckout/ThreeStepsInStoreCheckout';
import TopBanner from 'src/components/InStoreKYCAndCheckout/TopBanner';

export default function InStoreCheckout() {
  return (
    <Layout hasNoHeader hasNoFooter>
      <div>
        <TopBanner />
        <Badges />
        <Payment />
        <ThreeStepsInStoreCheckout />
        <FAQ />
        <BadgesBottom />
        <Footer />
        <FloatingButton noSmallText text="アプリを開いて決済画面に進む" />
      </div>
    </Layout>
  );
}
